import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Snehasish Chakraborty', // e.g: 'Name | Developer'
  lang: 'en', // e.g: en, es, fr, jp
  description: 'Welcome to my portfolio website', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Hello There!! I'm",
  name: 'Snehasish Chakraborty',
  subtitle: 'I am a Blogger✍, Gamer🎮 and a Software Engineer👨‍💻',
  cta: '',
};

// ABOUT DATA
export const aboutData = {
  img: 'profile1.jpg',
  paragraphOne: 'I am a full-time software engineer, working in a MNC as an Angular and Spring Boot developer. I have also worked in Documetum and D2 as a part of scratch Enterprise Content Management Platform development.',
  paragraphTwo: 'In my free time I do different side projects, mainly using react and vanilla JavaScript. I write technical blogs and try to demystify web tech stack as much as possible-for myself and for the community.',
  paragraphThree: 'I am also a gamer and Unity game developer. I have worked in many unity projects during my college days. Although I do not get that much of time to spend on game development now a days, but still, I am enthusiastic about knowing the Game Development Industry in a technical perspective.',
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'project1.jpg',
    title: '2D Twinstick Shooter Using Unity 2017 🎮',
    info: 'Created as a Hobby Project during 20217-2018. Took the initial Idea from a book and improved upon it. I started the development targeting PC as the platform, developed PC specific features like Controller support with full vibration feedback. Later on I switched to Android and had full release plan in my mind. Added android specific features like Google Play Achievements.',
    info2:
      'The game at the end never made it to Play Store, but you can download and play in your Android phone by clicking the button below.',
    url: 'https://snehasish-chakraborty.com/galaxy-defender.apk',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project2.jpg',
    title: 'Color Picker and Palette Creator Using React 🎨',
    info: 'This project is created using React, CSS in JS, higher order components along with Material UI for the styling. It is using chroma-js as the color manipulation library. For the client side routing react-router-dom is used.',
    info2: '',
    url: 'https://pallettey.snehasish-chakraborty.com/',
    repo: 'https://github.com/114snehasish/react-color-project', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'project3.JPG',
    title: 'LinkedIn Clone using React 💼',
    info: 'Created a basic Clone of the LinkedIn Login Page and the Dashboard/Feed section. Used React Functional Component, Hooks, Redux Toolkit for Logged in user\'s state management. Used firebase for Authentication, Real time data store and Hosting with custom domain.',
    info2: '',
    url: 'https://linkedinclone.snehasish-chakraborty.com/',
    repo: 'https://github.com/114snehasish/linkedin-clone', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: '',
  btn: '',
  email: 'contact@snehasish-chakraborty.com',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'pencil-square-o',
      url: 'https://blogs.snehasish-chakraborty.com',
    },
    {
      id: nanoid(),
      name: 'github',
      url: 'https://github.com/114snehasish',
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/snehasish-chakraborty-784262125/',
    },
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/thisissnehasish',
    },
    {
      id: nanoid(),
      name: 'facebook',
      url: 'https://www.facebook.com/snehasish.chakraborty.549/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
